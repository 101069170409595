.logo {
  max-height: calc(20vh - 20px);
  margin-bottom: 30px;
}

.BoardWelcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.BoardWelcome__Credentials {
  position: absolute;
  bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8em;
  text-align: center;
  line-height: 1.4;
}

.BoardWelcome__Credentials a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}

.BoardWelcome__Credentials a:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}