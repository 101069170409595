.BoardInfo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20% 60px 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 5px;
}