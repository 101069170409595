.ColorPicker {
  position: absolute;
  left: 5px;
  top: -45px;
  display: flex;
  background-color: #7a7f86;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 20px;
  border-radius: var(--border-radius-base);
  padding: 5px;
}

.ColorPicker__Anchor {
  position: absolute;
  content: "";
  border-width: 10px;
  border-style: solid;
  border-color: #7a7f86 transparent transparent transparent;
  top: 50px;
  left: 10px;
}

.ColorPicker__Item {
  width: 40px;
  height: 40px;
  margin-left: 5px;
  border-radius: var(--border-radius-base);
  text-align: right;
  color: rgba(0, 0, 0, 0.4);
  user-select: none;
  font-size: 0.6em;
  padding: 2px 4px 0 0;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 4px;
  box-sizing: border-box;
}

.ColorPicker__Item:first-child {
  margin-left: 0;
}

.ColorPicker__Item--selected {
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 4px inset;
}