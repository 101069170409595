.BoardLine {
  margin: 0 10px;
  color: #fff;
  display: flex;
  align-items: center;
  height: calc(9% - 1px);
  position: relative;
}

.Board-with-overlay .BoardLine {
  filter: blur(8px);
}

.BoardLine__Result {
  display: flex;
  padding-left: 20px;
  min-width: 65px;
  flex-grow: 1;
  justify-content: center;
}

.BoardLine__Result__Item {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.BoardLine__Result__Item--ColorMatch {
  background-color: #fff;
}

.BoardLine__Result__Item--PositionMatch {
  background-color: #000;
}

.BoardLine button {
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.6);
  padding: 5px;
  width: 100%;
  border-radius: 30px;
  text-shadow: black 0 0 1px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px inset;
}

.BoardLine button:focus {
  outline: none;
}