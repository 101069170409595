.Board {
  width: calc(60vh - 40px);
  max-width: 100%;
  background: rgba(255, 255, 255, 0.2);
  height: calc(100vh - 40px);
  margin: 20px auto;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 20px;
  position: relative;
  border-radius: var(--border-radius-base);
  overflow: hidden;
  padding: 0 20px;
  box-sizing: border-box;
  min-width: 380px;
}

.Board__Msg {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.4rem;
  margin-top: 10px;
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 5px;
}

.Board__Overlay {
  position: absolute;
  top: 12%;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 160px;
  text-shadow: rgba(0, 0, 0, 0.5) 0 0 30px;
  text-align: center;
  transition: all 2s ease-in;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.5);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%);
  cursor: pointer;
}

@media (max-width: 450px) {
  .Board {
    width: 100%;
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    margin: 0;
    border: none;
    box-shadow: none;
    transition: all 0.2s linear;
    border-radius: 0;
  }
}

.InfoButton {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 1%;
  right: 26px;
  background: none;
  border: none;
  cursor: pointer;
}

.InfoButton:focus {
  outline: none;
}